export default class Nfse {
    constructor() {
        this.id = "";
        this.idIntegracao = "";
        this.number = "";
        this.customer = { id: "", content: "" };
        this.natureOperation = { id: "", content: "" };
        this.output = true;
        this.codeDestination = { id: "", content: "" };
        this.codigoIdentificacaoDestino = 1;
        this.messageRejection = "";
        this.observation = "";
        this.status = "";
        this.rentId = "";
    }
    update(data) {
        this.id = data.id;
        this.idIntegracao = data.idIntegracao;
        this.output = data.output;
        this.number = data.number;
        this.natureOperation = { id: data.natureOperation, content: data.natureOperation };
        this.codeDestination = { id: data.codeDestination, content: data.codeDestination };
        this.codigoIdentificacaoDestino = data.codigoIdentificacaoDestino;
        this.customer = { id: data.customerSupplier.id, content: data.customerSupplier.person.companyName };
        this.messageRejection = data.messageRejection;
        this.observation = data.observation;
        this.status = data.status;
        this.rent = data.rent;
    }
}