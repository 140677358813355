<template>
  <div>
    <Loading type="line" :center="false" v-show="loading" />
    <div>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="5" xl="5">
          <Select title="Tipo" field="type" :formName="formName" :disabled="disabled" :required="true"
            :markFormDirty="false" url="/api/v1/stock/type-product/select-all" :showNewRegister="false"
            v-model="itemsNfe.typeProduct" :changed="cleanProduct" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="7" xl="7">
          <Select title="Produto" field="product" :formName="formName" :required="true" :markFormDirty="false"
            url="/api/v1/stock/product/select-all" :propsParams="{ any: itemsNfe.typeProduct.id }"
            :disabled="(!itemsNfe.typeProduct.id) || disabled" :showNewRegister="false" v-model="itemsNfe.product" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <InputText title="Descrição" field="description" :required="false" :markFormDirty="false" :maxLength="500"
            :disabled="disabled" v-model="itemsNfe.description" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <InputNumber title="Quantidade" field="quantity" :formName="formName" :required="true" :markFormDirty="false"
            :maxLength="5" :disabled="disabled" type="float" v-model="itemsNfe.quantity" />
        </b-col>
        <b-col sm="3">
          <InputDecimal title="Valor" field="value" :disabled="disabled" :formName="formName" :required="true"
            :markFormDirty="false" :maxLength="5" type="float" v-model="itemsNfe.value" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="div-btn text-right">
            <Button _key="btnUpdateItemRent" type="success" title="Salvar" :disabled="!isFormValid(formName) || disabled"
              classIcon="fas fa-save" size="medium" :clicked="saveItemRent" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import ItemsNfe from "../items-nfe/ItemsNfe";


import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsNfeUpdate",
  components: {
    InputNumber,
    InputDecimal,
    InputText,
    Select,
    EditorHtml,
    Button,
    Loading,
  },
  props: ['disabled'],
  data() {
    return {
      formName: "itemNfeUpdate",
      itemsNfe: new ItemsNfe(),
      urlUpdate: "/api/v1/tax-document/items-nfe/update",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveItemRent() {
      this.itemsNfe.productId = this.itemsNfe.product.id;

      let params = { url: this.urlUpdate, obj: this.itemsNfe };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "updateProduct" });
          this.hideModal();
        }
        this.removeLoading(["btnUpdateItemRent"]);
      });
    },
    update(data) {
      this.itemsNfe = new ItemsNfe();
      this.itemsNfe.update(data);
    },
    cleanProduct() {
      this.itemsNfe.product = { id: "", content: "" };
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "itemNfeUpdate") {
          this.update(event.data);
          this.loading = true;
          let self = this;
          setTimeout(function () {
            self.loading = false;
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
