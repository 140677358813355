export default class ItemsNfe {
    constructor() {
        this.id = "";
        this.typeProduct = { id: "", content: "" };
        this.product = { id: "", content: "" };
        this.description = "";
        this.quantity = 1;
        this.value = 0;
        this.nfeId = "";
    }
    update(data) {
        this.id = data.id;
        this.typeProduct = { id: data.typeProductId, content: data.typeProductName };
        this.product = { id: data.productId, content: data.productName };
        this.description = data.description;
        this.quantity = data.quantity;
        this.value = data.value;
    }
}