<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnNfseCreate" title="Nfe" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newInvoice" />
    <DropdownOptionsNfs v-if="id" type="nfe" :nf="nfe" />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge v-if="nfe.number" :title="'Nº' + nfe.number.toString()" type="primary" size="medium" />
          <Badge :title="titleStatus" :type="typeStatus" size="small" />
        </b-col>
      </b-row>
      <br>
      <div class="div-message-rejection" v-if="nfe.status == 3">
        <Alert type="danger">
          <span>
            {{ nfe.messageRejection }}
          </span>
        </Alert>
      </div>
    </div>
    <Molded>
      <b-row>
        <b-col sm="4">
          <RadioGroup :formName="formName" field="group" :options="[
      { text: 'Entrada', value: false },
      { text: 'Saída', value: true },
    ]" v-model="nfe.output" />
        </b-col>
        <b-col sm="8">
          <RadioGroup :formName="formName" field="group" :options="[
      { text: 'Operação Interna', value: 1 },
      { text: 'Operação Interestadual', value: 2 },
    ]" v-model="nfe.codigoIdentificacaoDestino" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <Select title="Natureza da Operação" field="type" :formName="formName" :required="false"
            url="/api/v1/tax-document/nature-operation/select-all" v-model="nfe.natureOperation"
            nameNewRegister="natureOperationCreateUpdate" titleNewRegister="Natureza da Operação"
            :widthNewRegister="500" :heightNewRegister="250">
            <NatureOperationCreateUpdate :registerInSelect="true" v-model="nfe.natureOperation" />
          </Select>
        </b-col>
        <b-col sm="8">
          <Select title="Destino (CFOP)" field="type" :formName="formName" :required="true"
            url="/api/v1/tax-document/cfop/select-all" v-model="nfe.codeDestination" nameNewRegister="cfopCreateUpdate"
            titleNewRegister="CFOP" :widthNewRegister="600" :heightNewRegister="250">
            <CfopCreateUpdate :registerInSelect="true" v-model="nfe.codeDestination" />
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <CustomerWithCreate :formName="formName" :disabled="disabled" v-model="nfe.customer" />
        </b-col>
      </b-row>
    </Molded>
    <br>
    <b-tabs v-if="id">
      <b-tab title="Produto">
        <ItemsNfeCreate v-if="!disabled" :nfe="nfe" />
        <br>
        <ItemsNfeList :disabled="disabled" :nfeId="id" />
      </b-tab>
      <b-tab title="Observações">
        <b-row>
          <b-col sm="12">
            <TextArea title="Observações" field="observation" :disabled="disabled" :required="false"
              v-model="nfe.observation" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <br />
  </div>
</template>

<script>


import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import DropdownOptionsNfs from '../shared/DropdownOptionsNfs.vue'

import CfopCreateUpdate from '../cfop/CfopCreateUpdate.vue'
import NatureOperationCreateUpdate from '../nature-operation/NatureOperationCreateUpdate.vue'
import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";
import ItemsNfeCreate from '../items-nfe/ItemsNfeCreate.vue'
import ItemsNfeList from '../items-nfe/ItemsNfeList.vue'

import Nfe from "@/components/modules/tax-document/nfe/Nfe.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "NfeCreateUpdate",
  components: {
    Molded,
    Button,
    Badge,
    SelectStatic,
    Select,
    RadioGroup,
    Alert,
    CustomerWithCreate,
    InputDecimal,
    TextArea,
    DropdownOptionsNfs,
    ItemsNfeCreate,
    ItemsNfeList, NatureOperationCreateUpdate, CfopCreateUpdate
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "nfseCreateUpdate",
      nfe: new Nfe(),
      urlCreate: "/api/v1/tax-document/nfe/create",
      urlUpdate: "/api/v1/tax-document/nfe/update",
      urlGetById: "/api/v1/tax-document/nfe/get-by-id",
      tabIndex: 0,
    }
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    titleStatus() {
      if (this.nfe.status == 1) return "Autorizada";
      if (this.nfe.status == 2) return "Concluída";
      if (this.nfe.status == 3) return "Rejeitada";
      if (this.nfe.status == 4) return "Cancelada";
      if (this.nfe.status == 5) return "Em processamento";
      if (this.nfe.status == 6) return "Pendente";
    },
    typeStatus() {
      if (this.nfe.status == 1) return "blue";
      if (this.nfe.status == 2) return "success";
      if (this.nfe.status == 3) return "danger";
      if (this.nfe.status == 4) return "danger";
      if (this.nfe.status == 5) return "info";
      if (this.nfe.status == 6) return "warning";
    },
    disabled() {
      if (this.nfe.status == 6 || !this.id) return false;
      return true;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.nfe };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "nfeUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.nfe };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.nfe.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "customerOnRent") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "updateNf") {
          this.getById();
        }
      },
      deep: true,
    },
    'nfe.taxation': {
      handler(taxation) {
        if (!this.nfe.description)
          this.nfe.description = taxation.description;
      },
      deep: true,
    }
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-message-rejection {
  margin-bottom: 30px;
}
</style>
