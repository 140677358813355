<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40"
          v-model="natureOperation.name" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveNatureOperation" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveNatureOperation" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import NatureOperation from "@/components/modules/tax-document/nature-operation/NatureOperation.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "NatureOperationCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText },
  data() {
    return {
      formName: "natureOperationCreateUpdate",
      natureOperation: new NatureOperation(),
      urlCreate: "/api/v1/tax-document/nature-operation/create",
      urlUpdate: "/api/v1/tax-document/nature-operation/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveNatureOperation() {
      if (this.natureOperation.id) {
        let params = { url: this.urlUpdate, obj: this.natureOperation };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["saveNatureOperation"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.natureOperation };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["saveNatureOperation"]);
        });
      }
    },
    update(data) {
      this.natureOperation = new NatureOperation();
      this.natureOperation.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "natureOperationCreate") {
          this.resetValidation(this.formName);
          this.natureOperation = new NatureOperation();
        }
        if (event.name == "natureOperationUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
