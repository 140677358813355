<template>
    <div>
        <div class="div-select-group">
            <Select v-if="module == 'rent'" title="Informe o Grupo" field="groupRent" :required="false" v-model="groupRent"
                url="/api/v1/rental/group-rent/select-all" nameNewRegister="groupRentCreateUpdate" titleNewRegister="Grupo"
                :widthNewRegister="500" :heightNewRegister="250">
                <GroupRentCreateUpdate :registerInSelect="true" v-model="groupRent" />
            </Select>
        </div>
        <b-row>
            <b-col sm="6">
                <b-form-checkbox v-model="showSelectAll" @change="select">
                    TODOS PRODUTOS
                </b-form-checkbox>
            </b-col>
        </b-row>
        <ScrollBar :minHeight="300" :maxHeight="500">
            <div>
                <table class="table table-responsive-xs">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="title-header">Descrição</th>
                            <th v-if="module == 'nfe'" class="title-header text-center">Ncm</th>
                            <th class="title-header text-center">Quantidade</th>
                            <th class="title-header text-center">Valor</th>
                        </tr>
                    </thead>
                    <tbody v-for="(data, groupName) in grouped">
                        <td class="group" colspan="8">
                            <div>
                                <span v-if="groupName" class="title-group">
                                    {{ groupName }}
                                </span>
                                <span v-else class="title-group">
                                    <span>Sem grupo</span>
                                </span>
                            </div>
                        </td>
                        <tr v-for="item in data">
                            <td class="td-checkbox">
                                <b-form-checkbox v-model="selected" :value="item" />
                            </td>
                            <td class="td-description">
                                <span> {{ item.productFullName }} </span>
                            </td>
                            <td v-if="module == 'nfe'" class="text-center">
                                <span> {{ item.ncm }} </span>
                            </td>
                            <td class="text-center td-quantity">
                                <span> {{ item.quantity }}</span>
                            </td>
                            <td class="td-total text-center">
                                <ItemsRentalTotalization :data="item" />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </ScrollBar>
        <div class="div-btn text-center">
            <Button _key="btnImport" title=" Importar" :disabled="selected.length == 0" classIcon="fa-solid fa-file-import"
                type="primary" size="medium" :clicked="executeImport" />
        </div>
    </div>
</template>
<script>



import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ItemsRentalTotalization from '../../items-rent/ItemsRentalTotalization.vue'
import GroupRentCreateUpdate from '../../group-rent/GroupRentCreateUpdate.vue'
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "ItemsImportList",
    props: {
        module: String,
        targetId: String,
        periodRentId: String,
        groupRentId: String,
        items: Array,
    },
    components: {
        ScrollBar,
        Button, ItemsRentalTotalization, GroupRentCreateUpdate, Select
    },
    data() {
        return {
            urlImport: "/api/v1/rental/import-product/import",
            selected: [],
            showSelectAll: false,
            groupRent: {}
        };
    },
    mounted() {
        this.select();
    },
    computed: {
        ...mapGetters("generic", ["groupBy"]),
        grouped() {
            var list = this.groupBy({
                array: this.items,
                key: "groupRentName",
            });
            return list;
        },
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        executeImport() {
            let params = {
                url: this.urlImport, obj: {
                    module: this.module,
                    targetId: this.targetId,
                    periodRentId: this.periodRentId,
                    groupRentId: this.groupRent.id,
                    items: this.selected
                }
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "itemsImported" });
                }
                this.removeLoading(["btnImport"]);
            });
        },
        select() {
            this.selected = [];
            if (this.showSelectAll) {
                for (let i in this.items) {
                    this.selected.push(this.items[i]);
                }
            }
        },
    },
};
</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-checkbox {
    width: 30px;
}

.td-description {
    max-width: 600px;
}

.td-quantity {
    max-width: 120px;
}

.td-total {
    max-width: 300px;
}

.div-select-group {
    margin-bottom: 30px;
}

.group {
    background-color: #fafafc;
    font-size: 14px;
    font-weight: 400;
}

.title-group {
    font-size: 14px;
    margin-left: 10px;
    color: #757d8c;
}
</style>
  