export default class Cfop {
    constructor() {
        this.id = "";
        this.name = "";
        this.code = "";
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
    }
}