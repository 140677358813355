<template>
    <div>
        <Button _key="btnImportByRent" title="Importar" classIcon="fa-solid fa-file-import" backGroundColor="#009183"
            color="white" size="small" :clicked="openImport" />

        <Modal title="Importar Produto da Locação" :width="modal.width" v-show="showModal('import')">

            <div v-if="items.length == 0">
                <Select title="Infome a Locação" field="type" :required="true" :showNewRegister="false"
                    url="/api/v1/rental/rent/select-all" v-model="rent" />

                <Select v-if="rent.any > 1" title="Período de Locação (opcional)" field="type" :required="false"
                    :propsParams="{ any: rent.id }" :showNewRegister="false" url="/api/v1/rental/period-rent/select-all"
                    v-model="periodRent" />

                <div class="div-btn text-center">
                    <Button _key="btnSearch" title="Buscar" classIcon="fa-solid fa-magnifying-glass" type="primary"
                        size="medium" :clicked="search" />
                </div>
                <Alert v-if="showMessage" type="warning">
                    <span> Não existe(m) produto(s) a ser importado! </span>
                </Alert>
            </div>

            <div v-else>
                <div class="text-right">
                    <Button _key="btnBack" classIcon="fa-solid fa-arrow-left" size="small" type="info" :clicked="back" />
                </div>
                <ItemsImportList :module="module" :targetId="targetId" :periodRentId="periodRentId" :items="items" />
            </div>
        </Modal>
    </div>
</template>
  
<script>

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ItemsImportList from './ItemsImportList.vue'

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
    name: "ImportProductByRent",
    props: ["module", "targetId", "periodRentId"],
    components: {
        Modal,
        Button,
        Select,
        Alert,
        ItemsImportList
    },
    data() {
        return {
            urlGetAll: "/api/v1/rental/import-product/search",
            rent: {},
            periodRent: {},
            items: [],
            modal: {
                width: 800
            },
            showMessage: false,
        };
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["getApi"]),
        openImport() {
            this.openModal("import");
            this.removeLoading(["btnImportByRent"]);
        },
        search() {
            this.showMessage = false;
            let params = {
                url: this.urlGetAll,
                obj: {
                    module: this.module,
                    targetId: this.targetId,
                    rentId: this.rent.id,
                    periodRentId: this.periodRent.id
                }
            };
            this.getApi(params).then((response) => {
                this.success = true;
                this.items = response.content;
                var size = this.items.length;
                if (size > 0) this.modal.width = 1100;
                if (size == 0) this.showMessage = true;

                this.removeLoading(["btnSearch"]);
            });
        },
        back() {
            this.modal.width = 800;
            this.items = [];
            this.removeLoading(["btnBack"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "itemsImported") {
                    this.back();
                    this.hideModal();
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.div-btn {
    margin: 20px;
}
</style>