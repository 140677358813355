<template>
  <div>
    <div>
      <div class="side-by-side">
        <Button _key="btnItemsNfeCreateUpdate" title="Produto" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
      <div class="side-by-side">
        <ImportProductByRent module="nfe" :targetId="nfe.id" />
      </div>
    </div>
    <Modal title="Produtos" :width="1200" :height="750" v-show="showModal('addProduct')" :onHideModal="hideSearchProduct">
      <SearchProduct v-if="showSearchProduct" :types="'1,2,0,0,0'" :status="1" :showPrice="false"
        :showReplacementValue="true" :showAvailability="false" :addProduct="addProduct">
      </SearchProduct>
    </Modal>
  </div>
</template>

<script>

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SearchProduct from '../../stock/search-product/SearchProduct.vue'
import ImportProductByRent from '../../rental/shared/import-product-rent/ImportProductByRent.vue'

import ItemsNfe from "./ItemsNfe.js";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "ItemsNfeCreate",
  components: {
    Modal,
    Button,
    SearchProduct, ImportProductByRent, ImportProductByRent
  },
  props: {
    nfe: Object,
  },
  data() {
    return {
      itemsNfe: new ItemsNfe(),
      urlCreate: "/api/v1/tax-document/items-nfe/create",
      showSearchProduct: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapActions("generic", ["postApi"]),
    create() {
      this.openModal("addProduct");
      this.removeLoading(["btnItemsNfeCreateUpdate"]);
      this.showSearchProduct = true;
    },
    addProduct(product) {
      this.updateClassItemsNfe(product);
      let params = { url: this.urlCreate, obj: this.itemsNfe };
      this.postApi(params).then((response) => {
        this.addEvent({ name: "addedProduct" });
        this.removeLoading([product.id]);
      });
    },
    hideSearchProduct() {
      this.showSearchProduct = false;
    },
    updateClassItemsNfe(data) {
      this.itemsNfe.productId = data.id;
      this.itemsNfe.description = data.description;
      this.itemsNfe.quantity = data.quantity;
      this.itemsNfe.value = data.replacementValue;
      this.itemsNfe.nfeId = this.nfe.id;
    },
  },
};
</script>
