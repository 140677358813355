<template>
  <div>
    <div class="fixed-bar-options" v-show="selected.length > 0">
      <Button _key="btnRemoveItem" type="danger" :title="'Remover ' + selected.length" size="small"
        :clicked="removeSelected" />
    </div>
    <b-row>
      <b-col sm="6">
        <b-form-checkbox v-model="showSelectAll" @change="select" v-if="!disabled">
          TODOS PRODUTOS
        </b-form-checkbox>
      </b-col>
      <b-col sm="6">
        <TableTotalRecords :totalRecords="content.totalRecords" />
      </b-col>
    </b-row>
    <ScrollBar :minHeight="330" :maxHeight="330">
      <div>
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th v-if="!disabled"></th>
              <th class="title-header">Descrição</th>
              <th class="title-header text-center">Ncm</th>
              <th class="title-header text-center">Quantidade</th>
              <th class="title-header text-center">Valor</th>
            </tr>
          </thead>
          <tbody v-for="itemNfe in content.data" :key="itemNfe.id">
            <td class="td-checkbox" v-if="!disabled">
              <b-form-checkbox v-model="selected" :value="itemNfe.id" />
            </td>
            <td class="td-edit td-description" @click="update(itemNfe)">
              <span> {{ itemNfe.productFullName }} </span>
            </td>
            <td class="td-edit text-center" @click="update(itemNfe)">
              <span> {{ itemNfe.ncm }} </span>
            </td>
            <td class="td-edit text-center td-quantity" @click="update(itemNfe)">
              <span> {{ itemNfe.quantity }}</span>
            </td>
            <td class="td-total text-center">
              <div>
                <small>Unit</small>
                {{ itemNfe.value | currency }}
                <span> | </span>
                <small>Total</small>
                {{ itemNfe.quantity * itemNfe.value | currency }}
              </div>
            </td>
          </tbody>
          <tbody v-show="content.data == 0">
            <tr>
              <td colspan="12">
                <span>Nenhum produto adicionado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ScrollBar>
    <Modal title="Produtos" :width="800" v-if="showModal('itemNfe')">
      <ItemsNfeUpdate :disabled="disabled" />
    </Modal>
  </div>
</template>

<script>


import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import ItemsNfeUpdate from './ItemsNfeUpdate.vue'

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "ItemsNfeList",
  props: {
    nfeId: String,
    disabled: Boolean,
  },
  components: {
    ScrollBar,
    Button,
    TableTotalRecords,
    Modal,
    ItemsNfeUpdate
  },
  data() {
    return {
      content: {
        data: [],
        totalRecords: 0,
      },
      selected: [],
      showSelectAll: false,
      urlGetApi: "/api/v1/tax-document/items-nfe/get-all",
      urlDelete: "/api/v1/tax-document/items-nfe/delete",
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    ...mapActions("generic", ["getApi", "deleteAllApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: {
          ...this.baseParams,
          any: this.nfeId,
          noPaginated: true,
        },
      };
      this.getApi(params).then((response) => {
        this.content.data = response.content.data;
        this.content.totalRecords = response.content.totalRecords;
        this.loading = false;
      });
    },
    create() {
      this.openModal("itemNfe");
      this.removeLoading(["btnItemsRentCreateUpdate"]);
    },
    update(itemNfe) {
      this.openModal("itemNfe");
      this.addEvent({ name: "itemNfeUpdate", data: itemNfe });
    },
    removeSelected() {
      let params = {
        url: this.urlDelete,
        selected: this.selected,
      };
      this.deleteAllApi(params).then((reponse) => {
        if (reponse.success) {
          this.removeLoading(["btnRemoveItem"]);
          this.selected = [];
          this.getAll();
        }
      });
    },
    select() {
      this.selected = [];
      if (this.showSelectAll) {
        for (let i in this.content.data) {
          this.selected.push(this.content.data[i].id);
        }
      }
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "addedProduct"
          || event.name == "updateProduct"
          || event.name == "itemsImported") {
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-checkbox {
  width: 30px;
}

.td-description {
  max-width: 600px;
}

.td-edit {
  cursor: pointer;
  background-color: #f1f4f9;
}

.td-quantity {
  max-width: 120px;
}

.td-total {
  max-width: 300px;
}

.div-btn {
  margin-top: 15px;
  margin-bottom: 20px;
}

.icon-danger {
  font-size: 20px;
  color: #f0134d;
  cursor: pointer;
}

.icon-danger:hover {
  color: #c70f40;
}
</style>
