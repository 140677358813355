<template>
  <div>
    <b-row>
      <b-col sm="4">
        <InputText title="Código" field="name" :formName="formName" :required="true" :maxLength="10" v-model="cfop.code"
          :markFormDirty="false" />
      </b-col>
      <b-col sm="8">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="100" v-model="cfop.name"
          :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveCfop" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveCfop" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Cfop from "@/components/modules/tax-document/cfop/Cfop.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CfopCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText },
  data() {
    return {
      formName: "cfopCreateUpdate",
      cfop: new Cfop(),
      urlCreate: "/api/v1/tax-document/cfop/create",
      urlUpdate: "/api/v1/tax-document/cfop/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCfop() {
      if (this.cfop.id) {
        let params = { url: this.urlUpdate, obj: this.cfop };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["saveCfop"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.cfop };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["saveCfop"]);
        });
      }
    },
    update(data) {
      this.cfop = new Cfop();
      this.cfop.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "cfopCreate") {
          this.resetValidation(this.formName);
          this.cfop = new Cfop();
        }
        if (event.name == "cfopUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
