<template>
  <div class="side-by-side">
    <Dropdown title="Opções" type="primary" size="small" classIcon="fa-solid fa-chevron-down" right="50"
      :items="optionsConditional" />
    <Modal :title="modalOptions.title" :width="550" v-if="showModal('optionsRent')" :onHideModal="closeModal">
      <div v-if="modalOptions.name == 'cancelNf'">
        <div v-if="type == 'nfse'">
          <Alert type="warning">
            <span>
              Atenção, ao cancelar uma Nota Fiscal alguns processos (se houver) serão executados, certifique-se que está
              cancelando a Nota correta, esta ação é irreversível...
            </span>
          </Alert>
          <div>
            <i class="fa-solid fa-circle-xmark icon-close"></i> Nota Fiscal ficará com status de Cancelada
          </div>
          <div>
            <i class="fa-solid fa-circle-xmark icon-close"></i> Pagamentos vinculados serão excluídos
          </div>
          <div>
            <i class="fa-solid fa-triangle-exclamation icon-warning"></i> Produtos da Locação serão liberados
            para novo Faturamento
          </div>
        </div>
        <Confirmation :isModal="false" title="Você tem certeza?" type="danger" :confirmed="cancel" />
      </div>
      <div v-if="modalOptions.name == 'generateNf'">

      </div>
      <div class="select-document" v-show="modalOptions.name == 'generateNf'">
        <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="generate" />
      </div>
    </Modal>
  </div>
</template>

<script>
import Dropdown from "@nixweb/nixloc-ui/src/component/forms/Dropdown.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "DropdownOptionsNfse",
  props: {
    type: String,
    nf: Object,
  },
  components: {
    Dropdown,
    Modal,
    Select,
    Confirmation,
    Alert,
  },
  data() {
    return {
      documentId: "",
      urlGetRps: `/api/v1/tax-document/nfse/get-rps`,
      urlGetPdf: `/api/v1/tax-document/${this.type}/get-pdf`,
      urlGetXml: `/api/v1/tax-document/${this.type}/get-xml`,
      urlCancelAllApi: `/api/v1/tax-document/${this.type}/cancel`,
      urlGenerateAllApi: `/api/v1/tax-document/${this.type}/generate`,
      modalOptions: {
        name: "",
        title: "",
        width: 0,
      },
      options: [
        {
          showByStatus: [6],
          title: "Gerar",
          classIcon: "fa-solid fa-retweet",
          eventName: "generateNf",
          style: "color:#007BFF;",
        },
        {
          showByStatus: [3],
          title: "Reemitir",
          classIcon: "fa-solid fa-retweet",
          eventName: "generateNf",
          style: "color:#007BFF;",
        },
        /* {
           showByStatus: [5],
           title: "Baixar RPS",
           classIcon: "fa-solid fa-file-pdf",
           eventName: "getRps",
         },*/
        {
          showByStatus: [1, 2, 4],
          title: "Baixar PDF",
          classIcon: "fa-solid fa-file-pdf",
          eventName: "getPdfNf",
        },
        {
          showByStatus: [1, 2, 4],
          title: "Baixar XML",
          classIcon: "fa-solid fa-memo-pad",
          eventName: "getXmlNf",
          hr: true,
        },
        {
          showByStatus: [1, 2],
          key: "cancel",
          title: "Cancelar",
          classIcon: "fa-solid fa-ban",
          style: "color:red;",
          eventName: "cancelNf",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    optionsConditional() {
      let options = [];
      this.options.forEach((option) => {
        if (option.showByStatus.includes(this.nf.status))
          options.push(option);
      });
      return options;
    },
  },
  methods: {
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "removeLoading",
    ]),
    ...mapActions("generic", ["getApi", "getFileApi", "putAllApi", "deleteAllApi"]),
    getRps() {
      let params = {
        url: this.urlGetRps,
        obj: {
          idIntegracao: this.nf.idIntegracao,
          fileName: `nf_${this.nf.number}`,
          fileType: "pdf"
        },
      };
      this.getFileApi(params).then(() => { });
    },
    getPdf() {
      let params = {
        url: this.urlGetPdf,
        obj: {
          idIntegracao: this.nf.idIntegracao,
          fileName: `nf_${this.nf.number}`,
          fileType: "pdf"
        },
      };
      this.getFileApi(params).then(() => { });
    },
    getXml() {
      let params = {
        url: this.urlGetXml,
        obj: {
          idIntegracao: this.nf.idIntegracao,
          fileName: `nf_${this.nf.number}`,
          fileType: "xml"
        },
      };
      this.getFileApi(params).then(() => { });
    },
    generate() {
      let params = {
        url: this.urlGenerateAllApi,
        selected: [this.nf.id],
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("optionsRent");
          this.addEvent({
            name: "updateNf",
          });
        }
        this.removeLoading(["confirm"]);
      });
    },
    cancel() {
      let params = {
        url: this.urlCancelAllApi,
        selected: [this.nf.id],
      };
      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.addEvent({
            name: "updateNf",
          });
          this.hideModal("cancelNf");
        }
        this.removeLoading(["confirm"]);
      });
    },
    closeModal() {
      this.modalOptions = { name: "", title: "", width: 0 };
    },
    download(url) {
      var link = document.createElement('a');
      link.href = url;
      link.download = url.substring(url.lastIndexOf('/') + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  watch: {
    event: {
      handler(event) {

        if (event.name == "getRps") {
          this.getRps();
        }

        if (event.name == "getPdfNf") {

          if (this.nf.urlPdf) {
            window.open(this.nf.urlPdf, '_blank');
          } else {
            this.getPdf();
          }
        }

        if (event.name == "getXmlNf") {
          this.getXml();
        }

        if (event.name == "generateNf") {
          this.modalOptions = {
            name: "generateNf",
            title: "Tem certeza que deseja gerar?",
            width: 500,
          };
          this.openModal("optionsRent");
        }


        if (event.name == "cancelNf") {
          this.modalOptions = {
            name: "cancelNf",
            title: "Tem certeza que deseja cancelar?",
            width: 600,
          };
          this.openModal("optionsRent");
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.select-document {
  min-height: 100px;
}

.icon-close {
  color: red;
}

.icon-warning {
  color: darkorange;
}
</style>